'use client'

import httpClient from '@apiClient'
import CheckboxContent from '@component/form/checkbox/content'
import useLogin from '@hooks/useLogin'
import { prospectUser } from '@plugin/login'
import { isEmail } from '@utils'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useAsyncFn, useBoolean } from 'react-use'

export const SUBSCRIBE_LATEST_NEWS_KEY = 'subscribe-latest-news'

export default function EmailSignUpForm() {

  const { user } = useLogin()
  const [isReceiveBizEmail, setIsReceiveBizEmail] = useState(1)
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useBoolean(false)

  useEffect(() => {
    if (user && user.email) {
      setEmail(user.email)
    }
  }, [user, setEmail])

  const [submitState, handleSubmit] = useAsyncFn(async (event) => {
    event.preventDefault()
    setError('')

    if (!isEmail(email)) {
      setError('Invalid email address')
      return
    }

    const { registered } = await httpClient.get('email/registered', { email })
    if (registered) {
      setError('This email address is already registered')
      return
    }

    await prospectUser({ email, isReceiveBizEmail })
    setSuccess(true)
    sessionStorage.setItem(SUBSCRIBE_LATEST_NEWS_KEY, true)
  }, [email, user, isReceiveBizEmail, setError, setSuccess])

  if (success) {
    return (
      <div className='h-full relative z-[1] section-wrap mx-auto text-center py-17'>
        <div className='text-3xl text-secondary'>Thank you!</div>
        <p className='text-xl mt-28 md:mt-0'>You’ve been added to our email list and will hear from us soon.</p>
      </div>
    )
  }

  return (
    <div className='h-full relative z-[1] section-wrap mx-auto flex flex-col md:flex-row items-center justify-center gap-x-66 gap-y-24'>
      <p className='w-452 max-w-full text-center text-xl md:text-3xl'>
        Get the latest <strong>property news</strong> and <strong>listings</strong> direct to your inbox
      </p>
      <form className='w-462 max-w-full' onSubmit={handleSubmit}>
        <div className='flex flex-col md:flex-row'>
          <input
            type='text'
            className={classNames(
              'md:flex-1 h-52 bg-white border rounded-sm md:rounded-r-none px-14 text-base focus:border-primary md:border-r-0 placeholder:text-neutral-3',
              Boolean(error) ? '!border-red' : 'border-neutral'
            )}
            placeholder='Enter your email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type='submit' className='button-orange w-126 !h-52 !text-base !rounded-l-none !hidden md:!flex'>
            {
              submitState.loading
                ? 'Submitting...'
                : 'Subscribe'
            }
          </button>
        </div>
        <div className='text-xs leading-12 text-red min-h-[12px]'>{error}</div>
        <CheckboxContent
          checked={isReceiveBizEmail}
          trueValue={1}
          falseValse={0}
          onChange={setIsReceiveBizEmail}
        >
          I would like to receive emails with property info, updates and more from OneRoof.
        </CheckboxContent>
        <button type='submit' className='button-orange !h-48 !text-base w-full mt-22 md:hidden'>
          {
            submitState.loading
              ? 'Submitting...'
              : 'Subscribe'
          }
        </button>
      </form>
    </div>
  )
}